import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import MenuIcon from '../../../public/icons/burger.svg';
import CloseIcon from '../../../public/icons/close.svg';
import {
  BLOG,
  CASHBACK_WIZARD_START,
  FAQS,
  FINANCE_MANAGER_DASHBOARD,
  FORUM,
  LOGIN,
  YOUR_DISCOUNT,
} from '../../../src/helpers/urlConstants';
import { checkAuthState } from '../../../src/helpers/tokenHelper';

const NAV_PANEL_HEIGHT = 58;

const MenuWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  // Note: we have to set this offset because AppBar gives us padding
  top: '-5px',
  left: '-12px',
  right: '-12px',
  width: '100vw',
  marginTop: `${NAV_PANEL_HEIGHT}px`,
  background: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'space-between',
  borderTop: `1px solid ${theme.palette.border?.main}`,
}));

const MobileMenuContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(4, 4),
  cursor: 'pointer',
  boxShadow: `inset 0px -1px 0px ${theme.palette.grey[200]}`,

  ['& .MuiTypography-root']: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    marginRight: theme.spacing(3),
  },
}));

const AuthenticationButton = ({ isAuthenticated }: { isAuthenticated: boolean | null }) => {
  const { t } = useTranslation();
  if (isAuthenticated === null) {
    return null;
  }

  return isAuthenticated ? (
    <ActionButton
      href={FINANCE_MANAGER_DASHBOARD}
      variant="contained"
      color="tertiary"
      size="small"
      data-eventid="to-app"
    >
      {t('components.layouts.redesign.header.actions.inApp')}
    </ActionButton>
  ) : (
    <ActionButton
      href={CASHBACK_WIZARD_START}
      variant="contained"
      color="primary"
      size="small"
      data-eventid="book-cashback-button"
    >
      {t('components.layouts.redesign.header.actions.cashback')}
    </ActionButton>
  );
};

export const HeaderMobileMenu = () => {
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  const router = useRouter();
  const theme = useTheme();
  const { t } = useTranslation();

  const setMenuHeight = useCallback(() => {
    setHeight(window.innerHeight - NAV_PANEL_HEIGHT);
  }, []);

  useEffect(() => {
    setIsAuthenticated(checkAuthState());
  }, []);

  useEffect(() => {
    setMenuHeight();
    window.addEventListener('resize', setMenuHeight);

    return () => {
      window.removeEventListener('resize', setMenuHeight);
    };
  }, []);

  // prevent scrolling when menu is open
  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'auto';
    document.body.style.height = open ? '100%' : 'auto';
  }, [open]);

  const navigateTo = (selector: string) => () => {
    const section = document.querySelector(selector);

    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setOpen(false);
    } else {
      router.push(`/${YOUR_DISCOUNT}`);
    }
  };

  return (
    <MobileMenuContainer>
      <AuthenticationButton isAuthenticated={isAuthenticated} />
      <IconButton
        size="small"
        onClick={() => {
          setOpen((item) => !item);
        }}
        sx={{ padding: 0 }}
      >
        {!open && <MenuIcon width="42" height="42" />}
        {open && <CloseIcon width="42" height="42" />}
      </IconButton>
      {open && (
        <MenuWrapper height={`${height}px`}>
          <List>
            <ListItemStyled onClick={navigateTo(YOUR_DISCOUNT)}>
              <Typography color="textSecondary">
                {t('components.layouts.redesign.header.actions.yourDiscount')}
              </Typography>
            </ListItemStyled>

            <ListItemStyled onClick={navigateTo(FAQS)}>
              <Typography color="textSecondary">
                {t('components.layouts.redesign.header.actions.faqs')}
              </Typography>
            </ListItemStyled>

            <Link href={FORUM} underline="hover">
              <ListItemStyled>
                <Typography color="textSecondary">
                  {t('components.layouts.redesign.header.actions.forum')}
                </Typography>
              </ListItemStyled>
            </Link>
            <Link href={BLOG} underline="hover">
              <ListItemStyled>
                <Typography color="textSecondary">
                  {t('components.layouts.redesign.header.actions.blog')}
                </Typography>
              </ListItemStyled>
            </Link>
            <Link href={`mailto:${t('customerService.email')}`} underline="hover">
              <ListItemStyled>
                <Typography color="textSecondary">
                  {t('components.layouts.redesign.header.actions.support')}
                </Typography>
              </ListItemStyled>
            </Link>
            {/*<Link href={ABOUT_US} underline="hover">*/}
            {/*  <ListItemStyled>*/}
            {/*    <Typography color="textSecondary">*/}
            {/*      {t('components.layouts.redesign.header.actions.aboutUs')}*/}
            {/*    </Typography>*/}
            {/*  </ListItemStyled>*/}
            {/*</Link>*/}
          </List>
          {!isAuthenticated && (
            <Box sx={{ padding: theme.spacing(0, 4, 11, 4) }}>
              <Button
                href={LOGIN}
                variant="outlined"
                fullWidth
                color="primary"
                data-eventid="register"
              >
                {t('components.layouts.redesign.header.actions.login')}
              </Button>
            </Box>
          )}
        </MenuWrapper>
      )}
    </MobileMenuContainer>
  );
};
